app-button-list-card {
    display: flex;
    justify-content: center;



    .button-list-card {
        @include card--max-width;
        @include card--quaternaire;

        &:not(.button-list-card--has-title) {
            .mat-card-content {
                @include rt-2;
            }
        }

        .button-list-card__content {

            .button-list-card__actions {

                .button-list-card__button {
                    @include quaternary-button;
                    height: auto;

                    .mat-list-item-content {
                        @include g-2;
                        @include p-0;
                        flex: auto;
                        justify-content: space-between;

                        .button-list-card__button-title {
                            transform: translateY(0.1em);
                        }

                        .button-list-card__button-state {
                            display: flex;
                            align-items: center;
                            gap: 0.4rem;
                            margin-left: auto;
                            padding: 0.4rem 0.4rem 0.4rem 1.2rem;
                            color: var(--white, #FFF);
                            background-color: var(--quaternairelabel, #B10745);
                            border-radius: 16.5px;

                            .button-list-card__button-label {
                                font-size: #{set-fluid-scale(12px, 16px)};
                                transform: translateY(0.1em);
                            }

                            .button-list-card__check-circle-icon,
                            .button-list-card__uncheck-circle-icon {
                                flex: none;
                                width: auto;
                                height: 2rem;
                                aspect-ratio: 1 / 1;

                                svg {
                                    width: 100%;
                                    min-width: unset;
                                    height: 100%;
                                    min-height: unset;
                                }
                            }
                        }
                    }

                    &.button-list-card__button--done {

                        &:not(:hover, :focus, :active, [disabled]) {
                            color: var(--quaternairebackground, #002940);
                        }

                        .mat-list-item-content {

                            .button-list-card__button-state {
                                color: var(--quaternairebackground, #002940) !important; // Override :hover, :focus and :active states defined in placeholders.scss
                                background-color: #F2F2F2;
                            }
                        }
                    }
                }
            }
        }
    }
}