@import "~normalize.css"; // https://byby.dev/normalize-css
@import "fuse__core-copy";

// --------------------------------------------------
// ABSTRACTS
// --------------------------------------------------

/**
 * The abstracts/ folder gathers all Sass tools and helpers used across the project. Every global variable, function, mixin and placeholder should be put in here. The rule of thumb for this folder is that it should not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
*/

@import "abstracts/variables";
@import "abstracts/colors";
@import "abstracts/backgrounds";
@import "abstracts/icons";

// --------------------------------------------------
// BASE
// --------------------------------------------------

/**
 * The base/ folder holds what we might call the boilerplate code for the project. In there, you might find the reset file, some typographic rules, and probably a stylesheet defining some standard styles for commonly used HTML elements (that I like to call _base.scss).
*/

@import "base/reset"; // https://css-tricks.com/an-interview-with-elad-shechter-on-the-new-css-reset/#top-of-site

// --------------------------------------------------
// MODULES
// --------------------------------------------------

// Activities
@import "../../@modules/activities/scss/main"; // Style sheets used by both the platform and the app

// Gamification
@import "../../@modules/gamification/scss/main";

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

/**
 * For smaller components, there is the components/ folder. While layout/ is macro (defining the global wireframe), components/ is more focused on widgets. It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines. There are usually a lot of files in components/ since the whole site/application should be mostly composed of tiny modules.
*/

@import "components/button";
@import "components/app-launcher";
@import "components/closing-pop-up";
@import "components/launch-button";
@import "components/list";
@import "components/select";
@import "components/dialog";
@import "components/score-indicator";
@import "components/end-screen-score";
@import "components/progress";

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------

/**
 * The layout/ folder contains everything that takes part in laying out the site or application. This folder could have stylesheets for the main parts of the site (header, footer, navigation, sidebar…), the grid system or even CSS styles for all the forms.
*/

@import "layout/app-dictionary";
@import "layout/app-language-lab";
@import "layout/dialog";
@import "layout/dialog-resume";
@import "layout/menu";
@import "layout/mat-sidenav-container";
@import "layout/mat-sidenav";
@import "layout/mat-nav-list";
@import "layout/mat-sidenav-content";
@import "layout/period-list";
@import "layout/app-header";
@import "layout/app-button-list-card";
@import "layout/app-lessons-in-chapters-list";
@import "layout/app-my-assignments";

// --------------------------------------------------
// PAGES
// --------------------------------------------------

/**
 * If you have page-specific styles, it is better to put them in a pages/ folder, in a file named after the page. For instance, it’s not uncommon to have very specific styles for the home page hence the need for a _home.scss file in pages/.
*/

@import "pages/_app-reward-page";