// --------------------------------------------------
// GENERIC DIALOG
// --------------------------------------------------

mat-dialog-container.mat-dialog-container {
    @include p-3;
    color: var(--text, #006AA6);
    border: 2px solid var(--progressionborder, #CEE1EC);
    border-radius: 32px;
    background: var(--appbackground, #FBF5F7);

    .mat-dialog-actions {
        margin-bottom: inherit;
    }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: rgba(0, 10, 16, 0.75);
    backdrop-filter: blur(2px);
}

mat-dialog-container .mat-dialog-content {
    max-height: 85vh;
}

// --------------------------------------------------
// SELECTION DIALOG
// --------------------------------------------------

fuse-selection-dialog {
    text-align: center;

    h2 {
        @include h1-font;
        text-transform: uppercase;
        word-wrap: anywhere;
    }

    p {
        @include body-font;
        @include mb-2;
    }

    img {
        width: 100%;
    }

    .mat-form-field, .field-submit {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .field-submit {
        @include mx-2;
        color: var(--secondarylabel);

        .mat-icon svg {
            fill: currentColor;
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        @include mt-1;
        @include pb-0;
        //.mat-select{} // voir theme/components/select
    }

    button.submit {

        @include secondary-button;
        @include px-2;
        @include py-1;
        @include mt-3;
        @include mb-2;
        //box-shadow: none;
        justify-content: space-between;
    }
}

// --------------------------------------------------
// DIALOG CONTAINING FULL SIZE IMAGE
// --------------------------------------------------

.fullsizeImage {
    // Variables
    $dialog-padding: $spacing-3;
    $dialog-title-height: 4rem;
    $dialog-title-margin-bottom: $spacing-2;
    // Style
    max-width: unset !important;

    .mat-dialog-container {
        @include m-auto;
        width: auto;
        height: auto;
        padding: $dialog-padding;

        fuse-confirm-dialog {
            display: block;

            .mat-dialog-title {
                height: $dialog-title-height;
                margin-bottom: $dialog-title-margin-bottom;

                button {
                    width: auto;
                    height: inherit;
                    aspect-ratio: 1 / 1;
                }
            }

            .mat-dialog-content {
                @include m-0;
                @include p-0;
                max-height: unset;

                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        object-fit: contain;
                        min-width: min(calc(50vw - ($dialog-padding * 2)), 30rem);
                        max-width: min(calc(90vw - ($dialog-padding * 2)), 150rem);
                        min-height: unset;
                        max-height: min(calc(90vh - (($dialog-padding * 2) + $dialog-title-height + $dialog-title-margin-bottom)), 150rem);

                        @media (orientation: landscape) {
                            min-width: unset;
                            min-height: min(calc(50vh - (($dialog-padding * 2) + $dialog-title-height + $dialog-title-margin-bottom)), 30rem);
                        }
                    }
                }
            }

            .mat-dialog-actions {
                display: none;
            }
        }
    }
}

// --------------------------------------------------
// help lesson DIALOG
// --------------------------------------------------
app-help-lessons-dialog, app-help-dictionary-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mat-dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            padding: 10px;
        }
    }
}