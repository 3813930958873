app-flashcard {

    .flashcard__figure {
        display: block;
        width: 100%;
        height: 100%;
        margin: unset;

        .flashcard__image {}

        .flashcard__caption {
            @include p-2;
            display: block;
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            opacity: 0;
            color: var(--white, #FFF);
            text-align: center;
            font-size: #{set-fluid-scale(20px, 40px)};
            font-weight: 900;
            line-height: 1;
            -webkit-text-stroke: #{set-fluid-scale(1px, 3px)} var(--primaryborder, #4B001C); // Property prefixed with -webkit but supported by all modern browsers
            transition: opacity 55ms linear;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .flashcard__audio {}
}