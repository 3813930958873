app-language-lab {

    .language-lab__select {
        @include secondary-button;
        position: sticky;
        z-index: 1;
        top: 0;
        width: auto;
        @include mx-2;
        text-transform: uppercase;

        .mat-select-trigger {

            .mat-select-value {
                color: inherit;
            }

            .mat-select-arrow-wrapper {
                width: 3.2rem;
                height: 3.2rem;
                background-color: var(--secondarylabel, #B10745);
                mask-image: url(/assets/langues_app/icons/arrow_drop_down_rounded_700.svg);
                mask-size: contain;
                mask-repeat: no-repeat;

                .mat-select-arrow {
                    display: none;
                }
            }
        }
    }

    .language-lab__cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(max(25rem, calc((100% - $spacing-3 * 3) / 4)), 1fr));
        @include g-3;
        @include p-2;
        @include mx-auto;
        width: 100%;
        max-width: 150rem;

        app-language-lab-card {

            .language-lab-card {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                @include g-0;
                width: 100%;
                max-width: 35rem;
                height: 100%;
                @include mx-auto;
                @include p-0;

                &:active {
                    padding-block: 0 !important;
                    background: var(--secondarybackground, #FFF);
                    box-shadow: none !important;
                }

                .language-lab-card__overlay {
                    position: absolute;
                    inset: 0;
                    background: rgba(var(--primaryborder-rgb), 0.25);
                }

                .language-lab-card__play-icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translate(-50%, -50%);
                    color: var(--primaryborder, #4B001C);
                    background-color: var(--primarylabel, #FFF);
                    border: 2px solid var(--primarybackground, #B10745);
                    border-radius: 50%;

                    svg {
                        width: 2rem;
                        height: 2rem;
                        min-width: 2rem;
                        min-height: 2rem;
                    }
                }

                .language-lab-card__image {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 16 / 9;
                    object-fit: contain;
                    border-radius: 10px 10px 0 0;
                }

                .language-lab-card__content {
                    flex: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: stretch;
                    @include g-2;
                    @include p-2;
                    color: var(--primarylabel, #FFF);
                    border-radius: 0 0 10px 10px;

                    .language-lab-card__data-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        @include g-2;

                        .language-lab-card__type,
                        .language-lab-card__count {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            @include g-1;
                            @include p-1;
                            border-radius: 6px;

                            span {
                                transform: translateY(0.2rem);
                            }
                        }

                        .language-lab-card__type {
                            color: var(--primaryborder, #4B001C);
                            background-color: var(--primarylabel, #FFF);
                            font-weight: 900;
                            line-height: 1.2;

                            span {
                                text-transform: uppercase;
                            }
                        }

                        .language-lab-card__count {
                            background-color: var(--primaryborder, #4B001C);
                            line-height: 1;
                        }
                    }
                }

                &:not(.language-lab-card--playing) {
                    border: 2px solid var(--primaryborder, #4B001C);
                    box-shadow: 0 4px 0 0 var(--primaryborder, #4B001C);

                    .language-lab-card__content {
                        border-top: 2px solid var(--primaryborder, #4B001C);
                        background: var(--primarybackground, #B10745);
                    }
                }

                &.language-lab-card--playing {
                    border: 2px solid var(--primarybackground, #B10745);

                    .language-lab-card__content {
                        border-top: 2px solid var(--primarybackground, #B10745);
                        background: var(--primaryborder, #4B001C);
                    }
                }
            }
        }

        audio {
            display: none;
        }
    }

    .language-lab__loader {
        @include p-3;
    }

    app-language-lab-card-player {

        .language-lab-card-player {
            height: 100vh;
            padding: $spacing-2 !important;

            .language-lab-card-player__header {
                display: grid;
                grid-template-columns: auto 1fr;
                @include g-2;

                .language-lab-card-player__close-button {
                    grid-column: 1 /span 1;
                    grid-row: 1 /span 1;
                }

                .language-lab-card-player__title {
                    grid-column: 2 /span 1;
                    grid-row: 1 /span 1;
                    align-self: end;
                    @include m-0;
                    text-align: center;
                    @include body-font;
                }

                .language-lab-card-player__progress-bar {
                    grid-column: 1 /span 2;
                    grid-row: 2 /span 1;
                    display: flex;
                    height: 20px;
                    background-color: var(--progressionbackground, #E5EBF1);
                    border: 2px solid var(--progressionborder, #CEE1EC);
                    @include r-2;
                    box-shadow: inset 0px 2px 0px 0px var(--progressionborder, #CEE1EC);

                    > div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 0;
                        @include rl-2;
                        background-color: var(--progressiongood, #009E94);
                        transition: width 400ms ease-in-out,
                                    border-top-right-radius 400ms ease-in-out,
                                    border-bottom-right-radius 400ms ease-in-out;

                        &::after {
                            content: "";
                            width: calc(100% - 4px);
                            height: calc(100% - 4px);
                            border-top: 2px solid rgba(255, 255, 255, 0.5);
                            border-radius: inherit;
                        }
                    }

                    &.language-lab-card-player__progress-bar--completed {

                        > div {
                            @include rr-2;
                        }
                    }
                }

                .language-lab-card-player__instructions {
                    grid-column: 1 /span 2;
                    grid-row: 3 /span 1;
                    @include p-0;
                }  
            }              

            .language-lab-card-player__content,
            .language-lab-card-player__footer {
                @include p-0;
            }

            .language-lab-card-player__footer {

                .language-lab-card-player__next-button {

                    .language-lab-card-player__next-button-label {
                        transform: translateY(0.1em);
                    }

                    .language-lab-card-player__next-button-icon {
                        flex: none;
                        width: 3rem;
                        height: auto;
                        aspect-ratio: 1 / 1;

                        &[ng-reflect-svg-icon="skip"] {
                            transform: scaleX(-1);
                        }

                        svg {
                            width: 100%;
                            min-width: unset;
                            height: 100%;
                            min-height: unset;
                        }
                    }
                }
            }
        }
    }
}