.mat-form-field-type-mat-select {

    mat-select {
        @include primary-button;
        
        .mat-select-value, .mat-select-arrow {
            color: var(--primarylabel, #FFFFFF);
            @include h3-font;
        }
    }

    &.mat-focused.mat-primary {

        .mat-select-arrow {
            color: var(--primarylabel, #FFFFFF);
        }
    }

    .mat-form-field-underline{
        display: none;
    }

}