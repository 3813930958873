.mat-list.mat-list-base {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;
}


mat-nav-list.mat-list-base {
    button.mat-list-item,
    button.mat-list-option {

        .mat-list-item-content {
            @include primary-button;
            flex: 1;
        }
    }
}