// || STRUCTURE CSS DOM
//
//  APP-SUMMARY-SUB-LESSON
//          div.page-layout.simple.inner-scroll
//              h1.recap-header
//              div.content
//                  div.activity-counter
//                  mat-card.activities-card

body.player.summary,
body.is-player-active.summary {

    #lessons app-stepper-wrapper > .page-layout > .center content .outlet-player-navigation > .previous-wrapper,
    #lessons app-stepper-wrapper > .page-layout > .center content .outlet-player-navigation > .next-wrapper,
    app-keyboard-latex-field {
        display: none;
    }

    // pas de navigation


    fuse-app-lessons {
        height: 100%;
    }

    mat-sidenav-content.mat-sidenav-content.headerVisible > .content {
        height: 100%;
    }

    mat-sidenav-content.mat-sidenav-content > .content {
        max-width: 100%;
    }

    /* ***********************************************************************************
    *                       app-summary-sub-lesson
     */
    app-summary-sub-lesson .page-layout.simple {
        height: 100vh;
        max-height: 100vh;
        overflow: auto;

        &.inner-scroll .mat-expansion-panel-body {
            flex: 1 1 0;
            padding: 20px 20%;
            @include media-breakpoint-down('sm') {
                padding: 20px 8%;
            }
        }

        /* ********************** mat-accordion ***************** */
        /* ****************************************************** */
        mat-accordion {
            flex: 1;
            display: flex;
            flex-direction: column;

            mat-expansion-panel {
                flex: none;
                display: flex;
                flex-direction: column;
                background-color: inherit;

            }
        }
    }
}


/* ***********************************************************************************
*                       app-summary-sub-lesson
 */
app-summary-sub-lesson {
    background-color: #F9FBFF;

    .summary {

        // --------------------------------------------------
        // GRADE
        // --------------------------------------------------

        .summary__grade-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            .summary__grade {
                padding: 1rem 3rem;
                background-color: var(--goodanswerstroke, #009E94);
                color: var(--white, #FFF);
                border-radius: 52px;
                font-size: 3.6rem;
                font-weight: 700;
                line-height: 1.2;
            }

            .summary__grade-feedback {
                max-width: 80rem;
                margin-block: unset;
                text-align: center;
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 1.4;
            }
        }

        // --------------------------------------------------
        // MAT-EXPANSION-PANEL
        // --------------------------------------------------

        mat-expansion-panel.mat-expansion-panel {
            &:not([class*=mat-elevation-z]) {
                box-shadow: none;
            }
        }

        mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header,
        mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):focus,
        mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
        mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:focus,
        mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:hover {
            background-color: transparent;
            height: auto;
        }

        .mat-expansion-panel-header {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: auto;
            padding: 0;

            .mat-content {
                background-color: #e8edf6;
                flex-direction: column;
                display: flex;
                align-items: center;
                align-self: stretch;
                margin: 0 0 40px 0;
                overflow: visible !important;
                overflow: auto;
                visibility: visible;
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);


                .mat-expansion-panel-header-title {
                    order: 2;
                    flex: 0;
                    margin: 0;

                    .recap-header {
                        margin: 0 0 -20px 0;

                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 26px 10px 30px;
                        gap: 9px;

                        height: 44px;
                        left: 496px;
                        top: 250px;

                        color: var(--secondarylabel, #B10745);
                        background: #FFFFFF;
                        border: 1px solid var(--secondaryborder, #B10745);
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
                        border-radius: 5px;
                        align-self: center;
                    }
                }

                .mat-expansion-panel-header-description {
                    order: 1;
                    flex: 0;
                    display: flex;
                    justify-content: center;
                    align-self: stretch;
                    margin: 0 0 50px 0;
                    padding: 16px;
                    background-color: white;
                    box-shadow: 0px 12px 40px -25px rgba(0, 0, 0, 0.5);
                    text-align: center;

                }
            }

            .mat-select-arrow {
                width: 24px;
                height: 24px;
                margin: 0 4px;
                border: none;
                background-color: var(--secondarylabel, #B10745);
                -webkit-mask-image: icon('chevron-up.svg');
                mask-image: icon('chevron-up.svg');
            }

            &.mat-expanded .mat-select-arrow {
                transform: rotate(180deg);
            }

        }

        mat-expansion-panel {
            /* -------- mat-expansion-panel-content */
            .mat-expansion-panel-content {
                /* -------- -- mat-expansion-panel-body */
                .mat-expansion-panel-body {
                    display: flex;
                    flex-direction: column;
                    gap: 37px;
                }

                /* -------- -- -- div.content */
            }
        }

        // --------------------------------------------------
        // CONTENT
        // --------------------------------------------------

        .bem-summary-sub-lesson__activity {
            @include r-3;

            &.discovered {
                background-color: var(--discoveredanswerstroke, #CEE1EC);
                border-top: 20px solid var(--discoveredanswerstroke, #CEE1EC);
                border-bottom: 20px solid var(--discoveredanswerstroke, #CEE1EC);
                border-left: 5px solid var(--discoveredanswerstroke, #CEE1EC);
                border-right: 5px solid var(--discoveredanswerstroke, #CEE1EC);

                .bem-summary-sub-lesson__activity-header {
                    background-color: var(--discoveredanswerbackground, #006AA6);
                }
            }

            &.was-correct {
                background-color: var(--goodanswerstroke, #009E94);
                border-top: 20px solid var(--goodanswerstroke, #009E94);
                border-bottom: 20px solid var(--goodanswerstroke, #009E94);
                border-left: 5px solid var(--goodanswerstroke, #009E94);
                border-right: 5px solid var(--goodanswerstroke, #009E94);

                .bem-summary-sub-lesson__activity-header {
                    background-color: var(--goodanswerbackground, #027F77);
                }
            }

            &.incorrect,
            &.missing {
                background-color: var(--badanswerstroke, #52758E);
                border-top: 20px solid var(--badanswerstroke, #52758E);
                border-bottom: 20px solid var(--badanswerstroke, #52758E);
                border-left: 5px solid var(--badanswerstroke, #52758E);
                border-right: 5px solid var(--badanswerstroke, #52758E);

                .bem-summary-sub-lesson__activity-header {
                    background-color: var(--badanswerbackground, #000A10);
                }
            }

            .bem-summary-sub-lesson__activity-header {
                position: relative;
                @include py-2;
                @include px-3;
                @include rt-2;
                min-height: 50px;
                color: var(--primarylabel--contrast, #FFF);
                text-align: center;

                .bem-summary-sub-lesson__activity-icon {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -75%);
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: inherit;

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }

                .bem-summary-sub-lesson__activity-counter {
                    display: none;
                }

                .bem-summary-sub-lesson__activity-title {
                    margin: unset;
                    font-size: 2rem;
                    font-weight: 700;
                }
            }

            .bem-summary-sub-lesson__activity-card {
                @include rb-2;

                app-multimedia,
                app-awareness,
                app-qcm,
                app-qcu,
                app-true-false,
                app-what-is-missing-audio,
                app-what-is-missing,
                app-text-matching,
                app-order-matching,
                app-short-answer,
                app-fill-in-blanks,
                app-memory,
                app-voice-recorder-activity,
                app-tool {

                    .activities-card.mat-card {
                        padding: 10px 0;
                    }

                    app-consignes .container {
                        min-height: 40px;
                    }

                    mat-card-footer {
                        display: none; // Hide every activity footer
                    }
                }
                app-qcm,
                app-qcu {
                    .activities-card > mat-card-content.mat-card-content {
                        @include media-breakpoint-up('sm') {
                            max-width: 97%;
                        }
                        button, .mat-checkbox {
                            padding: 0 10px;
                            .mat-checkbox-label, &.btn-option
                            {
                                line-height: 1.6rem !important;
                            }
                        }

                    }
                    .activities-card > mat-card-content.mat-card-content > app-consignes {
                        @include media-breakpoint-up('sm') {
                            flex: 3;
                        }
                    }

                }
            }
        }

        // --------------------------------------------------
        // FOOTER
        // --------------------------------------------------

        .summary__footer {
            flex: 1;
            padding: 20px;
            background-color: $summary__footer--background-color;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;

            button {
                min-width: 289px;

                + button {
                    margin-left: 0;
                }

                &.summary__next-button {
                    @include primary-button;
                }

                &:not(.summary__next-button) {
                    @include secondary-button;
                }
            }
        }

        // --------------------------------------------------
        // SUMMARY (GRADE WITHOUT DETAILS)
        // --------------------------------------------------

        &.summary--grade-only {
            @include p-3;
            justify-content: center;
            gap: 4rem;

            @include media-breakpoint(gt-xs) {
                background-image: $summary--background-image;
                background-size: $summary--background-size;
                background-position: $summary--background-position;
                background-repeat: $summary--background-repeat;
            }

            .summary__footer {
                flex: none;

                .summary__back-button {
                    @include primary-button;
                }
            }
        }
    }
}