@mixin icon-card-header($icon, $width: 85px, $height: 96px) {
    mat-card-header.mat-card-header:after {
        content: '';
        display: block;
        width: $width;
        height: $height;
        background: url($icon) no-repeat;
        position: absolute;
        right: 10px;
        bottom: 0;
    }
}

app-lessons-in-chapters-list {
    @include p-2;

    .content {
        gap: 50px;

        @extend %background-cover;

        .bem-app-lessons-in-chapters-list__back {
            margin-right: auto;
            @include secondary-button;

            .mat-button-wrapper {
                display: flex;
                align-items: center;
                @include g-1;
            }
        }

        app-button-list-card:nth-child(2n+0) {
            mat-card.mat-card {
                margin-left: #{set-fluid-scale(1px, 100px)};
            }
        }
        app-button-list-card:nth-child(2n+1) {
            mat-card.mat-card {
                margin-right: #{set-fluid-scale(1px, 100px)};
            }
        }

        app-button-list-card mat-card-header.mat-card-header {
            position: relative;
        }

        &.english--background {
            app-button-list-card:nth-child(3n+2) {
                @include icon-card-header('/assets/langues_app/images/avatars/persos_1.png');
            }
            app-button-list-card:nth-child(3n+0) {
                @include icon-card-header('/assets/langues_app/images/avatars/persos_2.png');
            }
            app-button-list-card:nth-child(3n+1) {
                @include icon-card-header('/assets/langues_app/images/avatars/persos_3.png', 81px, 92px);
            }
        }
    }

}