@use 'sass:math';

// --------------------------------------------------
// ORDER-MATCHING & TEXT-MATCHING
// --------------------------------------------------

@mixin matching-typology-layout(
    $forced-layout: false, // Force grid cell dimensions when grid items don't automatically expand (e.g. text buttons in order-matching component), sacrificing full responsive layout
) {

    // ---------- VARIABLES ----------

    // Aspect ratio
    $button-aspect-ratio: math.div(16, 9);

    // Minimum column/row size
    $min-grid-column-width: max(10rem, 10vw);
    $min-grid-row-height: calc($min-grid-column-width / $button-aspect-ratio);

    // Maximum column/row size
    $max-grid-column-width: 32.5rem;
    $max-grid-row-height: calc($max-grid-column-width / $button-aspect-ratio);

    // Maximum auto column/row size
    $max-grid-auto-column-width: auto;
    $max-grid-auto-row-height: $max-grid-auto-column-width;

    @if ($forced-layout == true) { // Update variables if layout is forced

        // Updated maximum auto column/row size
        $max-grid-auto-column-width: $max-grid-column-width;
        $max-grid-auto-row-height: calc($max-grid-auto-column-width / $button-aspect-ratio);
    }

    // ---------- STYLE ----------

    .mat-card-content {
        @include g-3;
        @include px-3;
        @include my-auto;
        flex: 1;
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(2, minmax($min-grid-column-width, $max-grid-column-width));
        grid-auto-rows: minmax($min-grid-row-height, $max-grid-auto-row-height);
        justify-content: center;
        align-content: center;
        max-height: calc(100vh - 40rem);

        @media (orientation: landscape) {
            grid-auto-flow: column;
            grid-template-columns: unset;
            grid-auto-columns: minmax($min-grid-column-width, $max-grid-auto-column-width);
            grid-template-rows: repeat(2, minmax($min-grid-row-height, $max-grid-row-height));
            grid-auto-rows: unset;
        }

        #container-for-answers-selected, // order-matching
        #container-for-answers-available, // order-matching
        .even-answers, // text-matching
        .odd-answers { // text-matching
            display: contents;

            > * { // Answer
                width: auto;
                max-width: 100%;
                height: 100%;
                max-height: 100%;
                aspect-ratio: $button-aspect-ratio;
                padding: unset;

                @media (orientation: landscape) {
                    width: 100%;
                    height: auto;
                }

                img {
                    border-radius: 9px;
                }

                &.button-text { // order-matching & text-matching

                    span, // order-matching
                    p { // text-matching
                        @include p-2;
                        overflow-y: auto;
                        overflow-x: hidden;
                        margin: unset;
                    }
                }
            }
        }

        #container-for-answers-available, // order-matching
        .even-answers { // text-matching

            > * { // Answer

                @for $i from 1 through 4 { // Enforce grid layout based on index of items in the child list of their parent

                    &:nth-child(#{$i}) {
                        grid-column: 1 /span 1;
                        grid-row: #{$i} / span 1;
                        justify-self: end;
                        align-self: stretch;

                        @media (orientation: landscape) {
                            grid-column: #{$i} / span 1;
                            grid-row: 2 /span 1;
                            justify-self: center;
                            align-self: start;
                        }
                    }
                }
            }
        }

        #container-for-answers-selected, // order-matching
        .odd-answers { // text-matching

            > * { // Answer

                @for $i from 1 through 4 { // Enforce grid layout based on index of items in the child list of their parent

                    &:nth-child(#{$i}) {
                        grid-column: 2 /span 1;
                        grid-row: #{$i} / span 1;
                        justify-self: start;
                        align-self: stretch;

                        @media (orientation: landscape) {
                            grid-column: #{$i} / span 1;
                            grid-row: 1 /span 1;
                            justify-self: center;
                            align-self: end;
                        }
                    }
                }
            }
        }
    }
}