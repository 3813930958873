mat-nav-list {
    &.mat-list-base {
        // Layout
        display: flex;
        flex-direction: column;
        @include g-1; // gap
    }
}
app-main-menu-element-generic {
    button {} // voir themes/.../component/list
}

div.mat-drawer-inner-container{
    display:flex;
    flex-direction:column;
}

app-main-menu{
    display: flex;
    flex: 1;
    flex-direction: column;
    mat-nav-list{
        justify-content: space-between;
        flex: 1;
        gap: 10px;
        .mat-nav-list-bottom, .mat-nav-list-top{
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 10px;
            .link{
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-block: 8px;
                padding-inline: 20px;
            }
            app-main-menu-element-link a{
                text-transform: uppercase;
                color: var(--white);
                justify-content: space-between;
            }
        }
        .mat-nav-list-top {
            a{
                @include primary-button;
            }
        }
        .mat-nav-list-bottom {
            justify-content: end;
            a{
                @include secondary-button;
                .mat-line{
                    color: var(--secondarylabel);
                }
            }
        }
        .mat-nav-list-top a, .mat-nav-list-bottom a{
            padding-block: 0 !important;
            padding-inline: 0 !important;
            font-size:#{set-fluid-scale(14px, 16px)} !important;
        }
    }
}